import * as React from 'react';
import { SelectInput, SelectInputProps, required as requiredValidate } from 'react-admin';
import { styled } from '@mui/system';

type Option = {
    value: string;
    name: string;
};

interface ShsSelectInputProps extends SelectInputProps {
    label?: string;
    onChangeValue?: (option: Option) => void;
}

export const selectInputSx = {
    '&.MuiFormControl-root': {
        margin: 0
    },
    '& .MuiInputBase-root': {
        background: '#ffffff'
    },
    '& legend': {
        display: 'none'
    },
    '& .MuiInputBase-input': {
        padding: '11px 16px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
        top: 0
    }
};

const StyledLabel = styled('label')({
    whiteSpace: 'nowrap'
});

const ShsSelectInput: React.FC<ShsSelectInputProps> = ({ label, required, onChangeValue, sx = {}, ...props }) => {
    const isRequired = () => (required ? '*' : '');
    const validateFn = [...(required ? [requiredValidate()] : [])];
    const handleChange = (e: any) => {
        const option = e.target as unknown as Option;
        onChangeValue && onChangeValue(option);
    };
    return (
        <div className="text_field__container top">
            <StyledLabel className="text_field__label">
                {label || ''}
                {isRequired()}
            </StyledLabel>
            <SelectInput
                validate={validateFn}
                label={false}
                sx={{ ...selectInputSx, ...(sx || {}) }}
                onChange={handleChange}
                {...props}
            />
        </div>
    );
};

export default ShsSelectInput;
