import React from 'react';
import { Container, Typography } from '@mui/material';
import { useCreatePath, TabbedForm, Edit, useRedirect } from 'react-admin';

import { editContainerStyles } from 'src/styles';

import ShsFormToolbar from 'src/components/common/ShsFormToolbar';
import { ShsReturnButton } from 'src/components/common';

import {
    CenterWorkingHours,
    GeneralCenterInfo,
    CenterLessonsDuration,
    CenterDepartment,
    CenterLessonsPlace
} from 'src/components/EducationalCenters/FieldGroups';
import { CenterFormFields, centerDefaultValues } from 'src/constants/centers';
import { getSchoolLessons } from 'src/utils/centers';

export const EducationalCenterEdit: React.FC = () => {
    const redirect = useRedirect();
    const createPath = useCreatePath();

    const onSuccess = () => {
        redirect(createPath({ resource: 'centers', type: 'list' }));
    };

    return (
        <Container sx={editContainerStyles} maxWidth={false}>
            <ShsReturnButton to={createPath({ resource: 'centers', type: 'list' })} label="Назад до освітніх центрів" />
            <Edit mutationMode="pessimistic" queryOptions={{ meta: { useMock: false } }}>
                <Typography variant="h4" component="h3" mb={4} fontWeight="700">
                    Редагування центру
                </Typography>
                <TabbedForm
                    toolbar={
                        <ShsFormToolbar deleteLabel="Видалити центр" enableDeleteButton handleCancel={onSuccess} />
                    }
                    defaultValues={centerDefaultValues}
                >
                    <TabbedForm.Tab label="Загальна інформація">
                        <GeneralCenterInfo />
                        <CenterWorkingHours />
                        <CenterLessonsDuration
                            educationalLevel="Дошкільна освіта"
                            lessonDurationSource={`${CenterFormFields.LESSONS_DURATION}.${CenterFormFields.BEFORE_SCHOOL_EDUCATION}.${CenterFormFields.LESSON_DURATION}`}
                            schoolLessons={getSchoolLessons(CenterFormFields.BEFORE_SCHOOL_EDUCATION)}
                            isTitleShown
                        />
                        <CenterLessonsDuration
                            educationalLevel="1-4 класи"
                            lessonDurationSource={`${CenterFormFields.LESSONS_DURATION}.${CenterFormFields.FIRST_FOURTH_GRADE}.${CenterFormFields.LESSON_DURATION}`}
                            schoolLessons={getSchoolLessons(CenterFormFields.FIRST_FOURTH_GRADE)}
                        />
                        <CenterLessonsDuration
                            educationalLevel="5-11 класи"
                            lessonDurationSource={`${CenterFormFields.LESSONS_DURATION}.${CenterFormFields.FIFTH_TENTH_GRADE}.${CenterFormFields.LESSON_DURATION}`}
                            schoolLessons={getSchoolLessons(CenterFormFields.FIFTH_TENTH_GRADE)}
                        />
                    </TabbedForm.Tab>
                    <TabbedForm.Tab label="Відділення">
                        <CenterDepartment />
                    </TabbedForm.Tab>
                    <TabbedForm.Tab label="Місця проведення уроків">
                        <CenterLessonsPlace />
                    </TabbedForm.Tab>
                </TabbedForm>
            </Edit>
        </Container>
    );
};
