import { Create, SimpleForm, useCreatePath, useNotify, useRedirect } from 'react-admin';
import React, { FC } from 'react';
import { Container, Typography } from '@mui/material';

import WithRole from '../../common/WithRole';
import { UserRole } from '../../../auth/types';
import { ShsReturnButton } from '../../common';
import { SubjectsCreateCard } from './SubjectsCreateCard';
import ShsFormToolbar from 'src/components/common/ShsFormToolbar';
import { Resource } from 'src/constants';

const SubjectCreate: FC = () => {
    const redirect = useRedirect();
    const notify = useNotify();
    const createPath = useCreatePath();

    const handleCancelBtnClick = () => {
        redirect(createPath({ resource: Resource.SUBJECTS, type: 'list' }));
    };

    return (
        <WithRole roles={[UserRole.ADMIN, UserRole.SYSTEM_ADMIN]}>
            <Container>
                <ShsReturnButton to="/subjects" label="Назад до навчального плану" />
                <Typography fontSize={40} fontWeight={700} sx={{ mb: 3 }}>
                    Додати предмет
                </Typography>
                <Create
                    mutationOptions={{
                        onSuccess: () => {
                            notify('Предмет успішно додано', {
                                type: 'success',
                                autoHideDuration: 2500
                            });
                            redirect('list', 'subjects');
                        }
                    }}
                    sx={{ '.RaCreate-card': { backgroundColor: 'transparent', boxShadow: 'none' } }}
                >
                    <SimpleForm toolbar={false} mode="onBlur" sx={{ padding: 0 }}>
                        <SubjectsCreateCard />
                        <ShsFormToolbar handleCancel={handleCancelBtnClick} />
                    </SimpleForm>
                </Create>
            </Container>
        </WithRole>
    );
};

export default SubjectCreate;
