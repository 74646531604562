import React from 'react';
import { ArrayInput, FormDataConsumer, SimpleFormIterator } from 'react-admin';
import { Box, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { disableFirstRemoveStyle } from 'src/styles';
import { ChildFormFields } from 'src/constants';
import { ShsButton } from 'src/components/common';

import VisitorData from './VisitorData';

const VisitorsList: React.FC = () => (
    <>
        <Typography variant="h6" component="h6" mb={1} mt={0} fontWeight="600">
            Законний представник дитини
        </Typography>
        <ArrayInput source={`${ChildFormFields.VISIT}.${ChildFormFields.VISITORS}`} label="">
            <SimpleFormIterator
                className="with-bottom-border"
                addButton={
                    <Box sx={{ mt: 1 }}>
                        <ShsButton
                            styleType="outline-no-border"
                            sx={{ paddingLeft: '0 !important' }}
                            label="Додати представника"
                            icon={<AddCircleOutlineIcon sx={{ fontSize: '24px !important' }} />}
                        />
                    </Box>
                }
                disableClear
                disableReordering
                sx={disableFirstRemoveStyle}
            >
                <FormDataConsumer>
                    {({ getSource }) => (getSource ? <VisitorData getSource={getSource} /> : null)}
                </FormDataConsumer>
            </SimpleFormIterator>
        </ArrayInput>
    </>
);

export default VisitorsList;
