import { FC } from 'react';
import { ShsAutoCompleteWithLoad, ShsSelectInput } from 'src/components/common';
import { Resource, UserRoleLabel } from 'src/constants';
import { UserRole } from 'src/auth/types';

const EXCLUDED_ROLES = [UserRole.SYSTEM_ADMIN, UserRole.SECRETARIAT, UserRole.PARENT];

export const ScheduleSidebar: FC = () => {
    return (
        <>
            <ShsSelectInput
                source="userRole"
                label="Для кого складаєте розклад"
                placeholder="Виберіть роль"
                choices={Object.entries(UserRoleLabel)
                    .filter(([id]) => !EXCLUDED_ROLES.includes(id as UserRole))
                    .map(([id, name]) => ({ id, name }))}
                defaultValue={UserRole.TEACHER}
                required
            />

            <ShsAutoCompleteWithLoad
                source="subject"
                pageUrl={Resource.SUBJECTS}
                keyField="id"
                textField="name"
                label="Для кого складаєте розклад"
                resultLimit={1000}
                required
            />
        </>
    );
};
