import React from 'react';
import { DeleteWithConfirmButton, Toolbar } from 'react-admin';
import { useFormState } from 'react-hook-form';
import { Box } from '@mui/material';

import { shsToolbarStyles } from 'src/styles';
import { ReactComponent as DeleteIcon } from 'src/assets/trash-can.svg';
import { ShsButton, ShsSaveButton } from 'src/components/common';

export type ModalProps = {
    isOpen: boolean;
    onConfirm?: () => void;
    onClose: () => void;
};

export interface ShsFormToolbarProps {
    submitLabel?: string;
    cancelLabel?: string;
    cancelDialog?: React.FC<ModalProps>;
    enableDeleteButton?: boolean;
    deleteLabel?: string;
    deleteConfirmContent?: React.ReactNode;

    handleCancel: () => void;
}

const ShsFormToolbar: React.FC<ShsFormToolbarProps> = ({
    submitLabel = 'Зберегти',
    cancelLabel = 'Скасувати',
    cancelDialog: CancelDialog,
    enableDeleteButton = false,
    deleteLabel = 'Видалити',
    deleteConfirmContent,
    handleCancel
}) => {
    const { isDirty } = useFormState();
    const [openCancel, setOpenCancel] = React.useState(false);

    const handleCancelClick = () => (CancelDialog && isDirty ? setOpenCancel(true) : handleCancel());
    const handleCancelDialogClose = () => setOpenCancel(false);

    return (
        <Toolbar sx={shsToolbarStyles} className="add-child-toolbar">
            <Box sx={{ flexGrow: 1 }}>
                <ShsSaveButton label={submitLabel} sx={{ mr: 2 }} />
                <ShsButton label={cancelLabel} styleType="outline" onClick={handleCancelClick} />

                {CancelDialog && (
                    <CancelDialog isOpen={openCancel} onConfirm={handleCancel} onClose={handleCancelDialogClose} />
                )}
            </Box>
            {enableDeleteButton && (
                <DeleteWithConfirmButton
                    label={deleteLabel}
                    icon={<DeleteIcon style={{ fill: '#F23030' }} />}
                    confirmTitle=""
                    confirmColor="warning"
                    confirmContent={deleteConfirmContent}
                />
            )}
        </Toolbar>
    );
};

export default ShsFormToolbar;
