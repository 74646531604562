// TODO bring the palette into proper shape
export const palette = {
    primary: {
        contrastText: '#fff',
        main: '#2FA9A6',
        dark: 'rgba(117, 202, 203, 0.10)',
        darkMedium: '#374151',
        midnightBlue: '#111928',
        optional: '#6B7280',
        green: '#DAF8E6',
        greenDark: '#1A8245',
        gray: '#C2C2C2',
        grayLight: '#E5E7EB',
        grayBluish: '#637381',
        creamyWhite: '#F9FAFB',
        inputBorder: '#DFE4EA'
    },
    error: {
        main: '#DC2626'
    },
    danger: {
        contrastText: '#fff',
        main: '#E10E0E',
        optional: '#F23030',
        warning: '#FEEBEB'
    },
    success: {
        contrastText: '#1A8245',
        green: '#22AD5C',
        main: '#DAF8E6'
    },
    lessonColors: [
        '#DC2626',
        '#EA580C',
        '#D97706',
        '#CA8A04',
        '#65A30D',
        '#16A34A',
        '#059669',
        '#0D9488',
        '#0891B2',
        '#0284C7',
        '#2563EB',
        '#4F46E5',
        '#7C3AED',
        '#9333EA',
        '#C026D3',
        '#DB2777',
        '#E11D48'
    ]
};

export const Theme = {
    palette,
    sidebar: {
        width: 280,
        closedWidth: 56
    },
    typography: {
        fontFamily: ['Inter', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
        body2: {
            fontSize: '16px'
        }
    },
    components: {
        // Override styles here but docs said "better to use sx"
        // Be careful when overriding styles - components are reused by the library
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    padding: '10px 16px',
                    color: 'rgba(107, 114, 128, 0.7)',
                    fill: 'rgba(107, 114, 128, 0.7)',
                    stroke: 'rgba(107, 114, 128, 0.7)',
                    fontWeight: 500,
                    fontSize: '1rem'
                },
                active: {
                    color: 'rgba(107, 114, 128, 0.7)'
                }
            }
        },
        RaDatagrid: {
            styleOverrides: {
                root: {
                    '& .RaDatagrid-headerCell': {
                        backgroundColor: palette.primary.creamyWhite,
                        padding: '18px 16px',
                        fontSize: '11px',
                        fontWeight: 600,
                        lineHeight: '14px'
                    },
                    '& .MuiTableBody-root > .MuiTableRow-root > .MuiTableCell-root > span': {
                        fontWeight: 400,
                        fontSize: '11px',
                        lineHeight: '14px',
                        color: palette.primary.grayBluish
                    },
                    '& .MuiTableBody-root > .MuiTableRow-root > .MuiTableCell-root > span.bold': {
                        fontWeight: 500,
                        color: palette.primary.midnightBlue
                    },
                    '& .RaEditButton-root': {
                        fill: palette.primary.grayBluish,
                        gap: 0,
                        padding: '8px',
                        minWidth: 'unset',
                        '& > span': {
                            margin: 0
                        }
                    }
                }
            }
        },
        RaList: {
            styleOverrides: {
                root: {
                    '& .RaList-content': {
                        marginTop: '16px',
                        border: '1px solid #dfe4ea',
                        borderRadius: '6px',
                        boxShadow: 'none'
                    },

                    '& .MuiTablePagination-root .MuiToolbar-root': {
                        display: 'flex',
                        padding: 0,
                        marginTop: '12px',
                        color: palette.primary.midnightBlue,
                        alignItems: 'center'
                    },

                    '& .MuiTablePagination-root .MuiTablePagination-spacer': {
                        flex: 0,
                        display: 'none'
                    },
                    '& .MuiTablePagination-root .MuiTablePagination-displayedRows': {
                        flex: 1,
                        order: 1,
                        margin: 0
                    },
                    '& .MuiTablePagination-root .MuiTablePagination-actions': {
                        flex: 1,
                        order: 2,

                        '& .MuiPaginationItem-root': {
                            borderRadius: '6px',
                            border: `1px solid ${palette.primary.inputBorder}`,
                            padding: '13px 8px',
                            backgroundColor: '#fff',
                            fontWeight: 400,
                            fontSize: '16px',
                            lineHeight: '18px',
                            color: palette.primary.grayBluish,
                            '&.Mui-selected': {
                                backgroundColor: palette.primary.main,
                                color: palette.primary.contrastText,
                                borderColor: palette.primary.main
                            }
                        }
                    },
                    '& .MuiTablePagination-root .MuiTablePagination-selectLabel': {
                        flex: 'none',
                        order: 3,
                        margin: 0
                    },
                    '& .MuiTablePagination-root .MuiTablePagination-input': {
                        flex: 0,
                        order: 4,
                        marginRight: 0,
                        backgroundColor: '#fff',
                        borderRadius: '6px',
                        border: `1px solid ${palette.primary.inputBorder}`
                    }
                }
            }
        },
        RaCreate: {
            styleOverrides: {
                root: {
                    '& .tabbed-form .MuiTabs-flexContainer > a': {
                        flex: 1
                    },
                    '& .tabbed-form .MuiCardContent-root': {
                        background: palette.primary.creamyWhite,
                        borderRadius: '6px',
                        marginTop: '22px',
                        padding: '24px 32px 24px 32px'
                    },
                    '& .MuiToolbar-root.RaToolbar-desktopToolbar': {
                        marginTop: '22px'
                    }
                }
            }
        },
        RaFilterForm: {
            styleOverrides: {
                root: {
                    '& .text_field__container.top': {
                        gap: 0,
                        '& .text_field__label': {
                            fontWeight: 400,
                            fontSize: '11px',
                            lineHeight: '14px',
                            color: palette.primary.grayBluish
                        }
                    },
                    '& .RaFilterForm-filterFormInput .MuiFormControl-root': {
                        margin: 0
                    },
                    '& .MuiInputBase-root': {
                        maxHeight: '40px',
                        minHeight: '40px',
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '24px'
                    },
                    '& .MuiInputBase-root.Mui-focused .MuiSvgIcon-root': {
                        color: palette.primary.main
                    },
                    '& .RaFilterForm-filterFormInput .MuiInputBase-input': {
                        padding: '8.5px 12px'
                    }
                }
            }
        },
        RaCreateButton: {
            styleOverrides: {
                root: {
                    minHeight: '40px',
                    borderRadius: '6px',
                    padding: '8px 16px',
                    gap: '10px',
                    textTransform: 'none',
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    textAlign: 'center',
                    '& .MuiButton-startIcon': {
                        margin: 0
                    }
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    color: palette.primary.grayBluish
                }
            }
        }
    }
};
