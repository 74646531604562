import * as React from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import EastIcon from '@mui/icons-material/East';

interface ForwardButtonProps {
    label: string;
    to?: string;
    onClick?: () => void;
    sx?: Record<string, any>;
}

const ForwardButton: React.FC<ForwardButtonProps> = ({ label, to, onClick, sx = {} }) => {
    if (to) {
        return (
            <Button component={Link} to={to} endIcon={<EastIcon />} sx={{ textTransform: 'none', ...sx }}>
                {label}
            </Button>
        );
    } else {
        return (
            <Button onClick={onClick} endIcon={<EastIcon />} sx={{ textTransform: 'none', ...sx }}>
                {label}
            </Button>
        );
    }
};

export default ForwardButton;
