import { UserRole } from '../auth/types';

export const FILTER_EMPTY_VALUE = 'ALL';

export enum Gender {
    FEMALE = 'FEMALE',
    MALE = 'MALE',
    OTHER = 'OTHER'
}

export const UserRoleLabel: { [key in UserRole]: string } = {
    [UserRole.SYSTEM_ADMIN]: 'Системний адміністратор',
    [UserRole.SECRETARIAT]: 'Секретаріат',
    [UserRole.ADMIN]: 'Завідувач',
    [UserRole.PSYCHOLOGIST]: 'Психолог',
    [UserRole.PRESCHOOL_TEACHER]: 'Вихователь',
    [UserRole.ASSISTANT_PRESCHOOL_TEACHER]: 'Асистент вихователя',
    [UserRole.JUNIORS_TEACHER]: 'Вчитель молодших класів',
    [UserRole.ASSISTANT_JUNIORS_TEACHER]: 'Асистент вчителя',
    [UserRole.TEACHER]: 'Вчитель',
    [UserRole.PARENT]: 'Представник дитини',
    [UserRole.SPEECH_THERAPIST]: 'Логопед',
    [UserRole.INTELLECTUAL_DISABILITY_TEACHER]: 'Олігофренопедагог',
    [UserRole.VISION_TEACHER]: 'Тифлопедагог',
    [UserRole.DEAF_TEACHER]: 'Сурдопедагог',
    [UserRole.METHODOLOGIST]: 'Методист'
};

export const BooleanStringMapping: { [key: string]: boolean } = {
    true: true,
    false: false
};

export const YesNoChoices = [
    { id: 'yes', value: true, name: 'Так' },
    { id: 'no', value: false, name: 'Ні' }
];

export enum STATUS {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    SUSPENDED = 'SUSPENDED'
}

export const StatusLabel = {
    [STATUS.ACTIVE]: 'Активний',
    [STATUS.INACTIVE]: 'Неактивний',
    [STATUS.SUSPENDED]: 'Відсторонений'
};

export enum StaffRate {
    RATE_1 = 'RATE_1',
    RATE_0_75 = 'RATE_0_75',
    RATE_0_5 = 'RATE_0_5'
}

export const StaffRateLabel: { [key in StaffRate]: string } = {
    RATE_1: 'Повна ставка',
    RATE_0_75: '0,75 ставки',
    RATE_0_5: '0,5 ставки'
};

export enum PassportType {
    ID_CARD = 'ID_CARD',
    OLD_PASSPORT = 'OLD_PASSPORT'
}

export const PassportLabel: { [key in PassportType]: string } = {
    ID_CARD: 'ID-картка',
    OLD_PASSPORT: 'Паспорт старого зразка'
};
export enum CalendarView {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    AGENDA = 'agenda'
}

export const CalendarViewList = [
    { id: CalendarView.DAY, name: 'День' },
    { id: CalendarView.WEEK, name: 'Тиждень' },
    { id: CalendarView.MONTH, name: 'Місяць' }
];

export enum DaysOfWeek {
    MONDAY = 'monday',
    TUESDAY = 'tuesday',
    WEDNESDAY = 'wednesday',
    THURSDAY = 'thursday',
    FRIDAY = 'friday',
    SATURDAY = 'saturday',
    SUNDAY = 'sunday'
}

export const DaysOfWeekLabel: { [key in DaysOfWeek]: string } = {
    [DaysOfWeek.MONDAY]: 'Понеділок',
    [DaysOfWeek.TUESDAY]: 'Вівторок',
    [DaysOfWeek.WEDNESDAY]: 'Середа',
    [DaysOfWeek.THURSDAY]: 'Четвер',
    [DaysOfWeek.FRIDAY]: "П'ятниця",
    [DaysOfWeek.SATURDAY]: 'Субота',
    [DaysOfWeek.SUNDAY]: 'Неділя'
};
