import { useFormContext } from 'react-hook-form';
import { SaveButton, useFormGroups } from 'react-admin';
import { Box } from '@mui/material';

import useTabbedFormNavigation from '../useTabIndex';
import tabGroups from '../const/tab-groups';
import { ShsButton } from 'src/components/common';
import { shsButtonStyles } from 'src/styles';

export const EmployeeCreateToolbar: React.FC = () => {
    const { tabIndex, navigateToTab } = useTabbedFormNavigation();
    const { trigger } = useFormContext();
    const formGroups = useFormGroups();
    const tabGroupName = tabGroups[tabIndex];
    const fields = formGroups.getGroupFields(tabGroupName);

    const handleBackwardBtnClick = () => {
        navigateToTab(tabIndex - 1);
    };

    const handleForwardBtnClick = async () => {
        const groupValid = await trigger(fields, {
            shouldFocus: true
        });

        if (groupValid) {
            navigateToTab(tabIndex + 1);
        }
    };

    return (
        <Box display="flex" gap="12px" alignItems="center" padding="24px 0">
            {tabIndex > 0 && <ShsButton styleType="outline" label="Назад" onClick={handleBackwardBtnClick} />}
            {tabIndex !== tabGroups.length - 1 ? (
                <ShsButton label="Далі" onClick={handleForwardBtnClick} />
            ) : (
                <SaveButton label="Додати працівника" sx={{ ...shsButtonStyles }} icon={<i></i>} />
            )}
        </Box>
    );
};
