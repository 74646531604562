import { FC } from 'react';
import { useDataProvider } from 'react-admin';
import { ShsCalendar } from 'src/components/common';
import { EducationLevelGroup, Resource } from 'src/constants';
import { useQuery } from 'react-query';
import { CalendarProps } from 'react-big-calendar';

export const ScheduleCalendar: FC<{ educationLevelGroup: EducationLevelGroup }> = ({ educationLevelGroup }) => {
    const dataProvider = useDataProvider();
    const { data } = useQuery<CalendarProps['events']>(educationLevelGroup, () =>
        dataProvider.getGroupList(Resource.SCHEDULE, {
            meta: { educationLevelGroup }
        })
    );

    console.log(educationLevelGroup, data);

    return <ShsCalendar events={data} />;
};
