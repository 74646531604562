import React, { useEffect, useState } from 'react';
import {
    CreateButton,
    Datagrid,
    EditButton,
    Filter,
    FunctionField,
    List,
    TopToolbar,
    useListContext
} from 'react-admin';
import { Container, Tab, Tabs, Box } from '@mui/material';
import { ShsSearchInput, ShsTitle, StatusBadge, WithRole } from '../common';
import { listContainerStyles } from 'src/styles';
import { STATUS } from 'src/constants';
import { UserRole } from 'src/auth/types';
import { ReactComponent as EditIcon } from 'src/assets/pencil-alt.svg';
import { EducationCenterType } from '../Employees/types';

const ToolbarActions = () => {
    return (
        <TopToolbar>
            <WithRole roles={[UserRole.ADMIN, UserRole.SYSTEM_ADMIN]}>
                <CreateButton label="Додати освітній центр" variant="contained" resource="centers" />
            </WithRole>
        </TopToolbar>
    );
};

const CentersGrid: React.FC<{ onTotalChanged?: (total: number) => void }> = ({ onTotalChanged }) => {
    const { total } = useListContext();

    useEffect(() => onTotalChanged?.(total), [total]);

    return (
        <Datagrid bulkActionButtons={false} rowClick="edit">
            <FunctionField
                sortBy="name"
                label="Повна назва освітнього центру"
                render={({ name }: EducationCenterType) => <Box>{name}</Box>}
            />
            <FunctionField
                sortBy="shortName"
                label="Скорочена назва"
                render={({ shortName }: EducationCenterType) => <Box>{shortName}</Box>}
            />
            <FunctionField sortBy="city" label="Місто" render={({ city }: EducationCenterType) => <Box>{city}</Box>} />
            <FunctionField
                sortBy="address"
                label="Адреса"
                render={({ address }: EducationCenterType) => <Box>{address}</Box>}
            />
            <FunctionField label="Телефон" render={({ phone }: EducationCenterType) => <Box>{phone}</Box>} />
            <FunctionField
                label="Статус"
                render={({ active }: EducationCenterType) => (
                    <StatusBadge status={active ? STATUS.ACTIVE : STATUS.INACTIVE} />
                )}
            />
            <EditButton icon={<EditIcon />} label="" />
        </Datagrid>
    );
};

export const EducationalCentersList: React.FC = () => {
    const [activeTab, setActiveTab] = useState<STATUS.ACTIVE | STATUS.INACTIVE>(STATUS.ACTIVE);
    const [total, setTotal] = useState<number | undefined>(0);

    return (
        <Container sx={listContainerStyles}>
            <ShsTitle title="Освітні центри" found={total} foundTitle="Загалом" />
            <List<EducationCenterType>
                resource="centers"
                exporter={false}
                empty={false}
                actions={<ToolbarActions />}
                filters={
                    <Filter>
                        <ShsSearchInput source="keyword" label="Знайти назву освітнього центру" alwaysOn />
                    </Filter>
                }
                filter={activeTab == STATUS.ACTIVE ? { active: true } : { active: false }}
            >
                <Tabs value={activeTab} onChange={(e, value) => setActiveTab(value)}>
                    <Tab value={STATUS.ACTIVE} label="Активні" />
                    <Tab value={STATUS.INACTIVE} label="Неактивні" />
                </Tabs>
                <CentersGrid onTotalChanged={(total) => setTotal(total)} />
            </List>
        </Container>
    );
};
