import React from 'react';
import { useCreatePath, useRedirect } from 'react-admin';
import { CancelEditModal, DeleteModal } from 'src/components/Children/modals';
import ShsFormToolbar from '../../common/ShsFormToolbar';

interface AddToolbarProps {
    editMode?: boolean;
}

const AddToolbar: React.FC<AddToolbarProps> = ({ editMode }) => {
    const redirect = useRedirect();
    const createPath = useCreatePath();
    const handleCancelConfirm = () => {
        redirect(createPath({ resource: 'children', type: 'list' }));
    };

    return (
        <ShsFormToolbar
            handleCancel={handleCancelConfirm}
            cancelDialog={CancelEditModal}
            submitLabel={editMode ? 'Редагувати' : 'Додати дитину'}
            enableDeleteButton={editMode}
            deleteConfirmContent={<DeleteModal />}
        />
    );
};

export default AddToolbar;
