import React from 'react';
import { Typography, Grid, Box } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ArrayInput, SimpleFormIterator, FormDataConsumer } from 'react-admin';

import { ShsTextInput, ShsButton } from 'src/components/common';
import ShsCheckboxGroup from 'src/components/common/Fields/ShsCheckboxGroup';
import { CenterFormFields, SchoolLabels } from 'src/constants/centers';

import { disableFirstRemoveStyle, formIteratorStyles } from 'src/styles';

export const CenterLessonsPlace: React.FC = () => {
    return (
        <>
            <Typography variant="h6" component="h6" mb={3} fontWeight="600">
                Місця проведення уроків
            </Typography>
            <ArrayInput source={CenterFormFields.CLASSROOM_LOCATIONS} label="">
                <SimpleFormIterator
                    addButton={
                        <ShsButton
                            styleType="text"
                            label="Додати місце проведення уроків"
                            icon={<AddCircleOutlineIcon />}
                        />
                    }
                    disableReordering
                    disableClear
                    fullWidth
                    sx={{ ...disableFirstRemoveStyle, ...formIteratorStyles }}
                >
                    <FormDataConsumer>
                        {({ getSource }) => (
                            <Box>
                                <Grid container columnSpacing={4} rowSpacing={1} sx={{ mt: 2 }}>
                                    <Grid item xs={12}>
                                        <ShsTextInput
                                            source={getSource(CenterFormFields.NAME)}
                                            label="Назва місця"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <ShsTextInput source={getSource(CenterFormFields.BUILDING)} label="Корпус" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <ShsTextInput source={getSource(CenterFormFields.FLOOR)} label="Поверх" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <ShsTextInput source={getSource(CenterFormFields.BLOCK)} label="Блок" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <ShsTextInput
                                            source={getSource(CenterFormFields.ROOM_NUMBER)}
                                            label="№ кабінету"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ShsTextInput
                                            source={getSource(CenterFormFields.MAX_SEATING_CAPACITY)}
                                            label="Максимальна к-сть місць"
                                        />
                                    </Grid>
                                </Grid>
                                <Box display="flex" flexDirection={'column'}>
                                    <ShsCheckboxGroup
                                        source={getSource(CenterFormFields.TARGER_GRADE_RANGE)}
                                        label="Для кого призначене місце проведення уроків"
                                        choices={SchoolLabels}
                                        required
                                    />
                                </Box>
                            </Box>
                        )}
                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>
        </>
    );
};
