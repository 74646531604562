import React from 'react';
import { EducationalCentersList } from 'src/components/EducationalCenters/EducationalCentersList';
import { EducationalCenterAdd } from 'src/components/EducationalCenters/EducationalCenterAdd';
import { EducationalCenterEdit } from 'src/components/EducationalCenters/EducationalCenterEdit';
import WithRole from 'src/components/common/WithRole';
import { UserRole } from 'src/auth/types';

export default {
    list: EducationalCentersList,
    create: (
        <WithRole roles={UserRole.SYSTEM_ADMIN}>
            <EducationalCenterAdd />
        </WithRole>
    ),
    edit: (
        <WithRole roles={[UserRole.ADMIN, UserRole.SYSTEM_ADMIN]}>
            <EducationalCenterEdit />
        </WithRole>
    )
};
