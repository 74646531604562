import React from 'react';
import WithRole from 'src/components/common/WithRole';
import { UserRole } from 'src/auth/types';
import NoAccess from 'src/components/common/Errors/NoAccess';
import EmployeeList from './EmployeeList';
import { EmployeeCreate } from './EmployeeCreate';

export default {
    list: (
        <WithRole roles={[UserRole.SYSTEM_ADMIN, UserRole.SECRETARIAT, UserRole.ADMIN]} orElse={<NoAccess />}>
            <EmployeeList />
        </WithRole>
    ),
    create: (
        <WithRole roles={[UserRole.SYSTEM_ADMIN, UserRole.ADMIN]} orElse={<NoAccess />}>
            <EmployeeCreate />
        </WithRole>
    )
};
