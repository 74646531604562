import React from 'react';
import { Typography, Grid, Box, Divider } from '@mui/material';

import { ChildFormFields, ChildGenderList } from 'src/constants';
import { ShsTextInput, ShsRadioButtonGroup, ShsDatePicker } from 'src/components/common';

export const PersonalData: React.FC = () => {
    return (
        <>
            <Typography variant="h6" component="h6" mb={3} fontWeight="600">
                Персональні дані дитини
            </Typography>
            <Grid container columnSpacing={4} rowSpacing={1}>
                <Grid item xs={6}>
                    <ShsTextInput
                        source={ChildFormFields.BIRTH_CERTIFICATE_ID}
                        label="№ свідоцтва про народження"
                        required
                    />
                </Grid>
                <Box width="100%" />
                <Grid item xs={6}>
                    <ShsTextInput source={ChildFormFields.FIRST_NAME} label="Ім'я" required />
                </Grid>
                <Grid item xs={6}>
                    <ShsTextInput source={ChildFormFields.LAST_NAME} label="Прізвище" required />
                </Grid>
                <Grid item xs={6}>
                    <ShsTextInput source={ChildFormFields.PATRONYMIC} label="По батькові" />
                </Grid>
                <Grid item xs={6}>
                    <ShsDatePicker source={ChildFormFields.BIRTH_DATE} label="День народження" required />
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{ mb: 2, mt: 1 }} />
                    <ShsRadioButtonGroup source={ChildFormFields.GENDER} choices={ChildGenderList} label="Стать" />
                    <Divider sx={{ mt: -1, mb: 3 }} />
                </Grid>
                <Grid item xs={6}>
                    <ShsTextInput
                        source={`${ChildFormFields.VISIT}.${ChildFormFields.RESIDENCE}`}
                        label="Місце фактичного проживання"
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <ShsTextInput
                        source={`${ChildFormFields.VISIT}.${ChildFormFields.REGISTRATION}`}
                        label="Місце реєстрації"
                        required
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default PersonalData;
