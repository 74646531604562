import React from 'react';
import { InputHelperText, useLocaleState, required as requiredValidate, useInput, TextInputProps } from 'react-admin';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled } from '@mui/system';
import { format } from 'date-fns';
import * as DateFnsLocales from 'date-fns/locale';
import type { Locale } from 'date-fns';
import cn from 'classnames';

import { LocaleMapping } from 'src/constants';

interface ShsDatePickerProps extends TextInputProps {
    label?: string;
    required?: boolean;
    labelPosition?: 'top' | 'left';
}

const styles = ({ labelPosition }: { labelPosition?: 'top' | 'left' }) => ({
    '&.MuiFormControl-root': {
        margin: '0 0 ' + (labelPosition === 'left' ? '20px' : '0') + ' 0'
    },
    '&.MuiFormControl-root.invalid .MuiOutlinedInput-notchedOutline': {
        borderColor: '#d32f2f'
    },
    '&.MuiFormControl-root.invalid .Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#2FA9A6 !important'
    },
    '& legend': {
        display: 'none'
    },
    '& input.MuiInputBase-input': {
        background: '#ffffff',
        padding: '11px 16px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
        top: 0
    },
    '& .error': {
        color: '#d32f2f',
        fontSize: '0.75rem',
        padding: 0
    }
});

const StyledDatePicker = styled(DatePicker)(styles);

const StyledErrors = styled('p')({
    color: '#d32f2f',
    fontSize: '0.75rem',
    padding: 0,
    margin: '-5px 14px 0 2px'
});

const ShsDatePicker: React.FC<ShsDatePickerProps> = ({ source, label, required, labelPosition }) => {
    const [value, setValue] = React.useState<Date | null>(null);

    const [locale] = useLocaleState();
    const currentLocale = LocaleMapping[locale] || locale || 'enUS';
    const locales = DateFnsLocales as Record<string, Locale>;
    const adapterLocale = locales[currentLocale];

    const isRequired = () => (required ? '*' : '');
    const validateFn = [...(required ? [requiredValidate()] : [])];

    const {
        field,
        fieldState: { isTouched, invalid, error },
        formState: { isSubmitted }
    } = useInput({ source, isRequired: required, validate: validateFn });

    React.useEffect(() => {
        const defaultValue = field.value ? new Date(field.value) : null;
        setValue(defaultValue);
    }, []);

    const handleChange = (newValue: Date | null) => {
        setValue(newValue);
        field.onChange(newValue, format(newValue as Date, 'yyyy-MM-dd'));
    };

    const renderHelperText = (isTouched || isSubmitted) && invalid;

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={adapterLocale}>
            <div className={cn('text_field__container', labelPosition ? labelPosition : 'top')}>
                {label && (
                    <label className="text_field__label">
                        {label || ''}
                        {isRequired()}
                    </label>
                )}
                <StyledDatePicker
                    value={value}
                    onChange={handleChange}
                    className={invalid ? 'invalid' : ''}
                    slotProps={{ textField: { placeholder: 'ДД.ММ.РРРР' } }}
                    labelPosition={labelPosition}
                />
                {renderHelperText ? (
                    <StyledErrors>
                        <InputHelperText touched={isTouched || isSubmitted} error={error?.message} />
                    </StyledErrors>
                ) : null}
            </div>
        </LocalizationProvider>
    );
};

export default ShsDatePicker;
