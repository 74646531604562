import { GetListParams } from 'react-admin';
import { CalendarProps } from 'react-big-calendar';

const scheduleMock: CalendarProps['events'] = [
    {
        start: new Date(new Date().getTime() - 1000 * 60 * 60 * 28),
        end: new Date(new Date().getTime() - 1000 * 60 * 60 * 24),
        title: 'yesterday event',
        allDay: false
    },
    {
        start: new Date(),
        end: new Date(),
        title: 'today event',
        allDay: true
    },
    {
        start: new Date(new Date().getTime() + 1000 * 60 * 60 * 24),
        end: new Date(new Date().getTime() + 1000 * 60 * 60 * 28),
        title: 'tomorrow event',
        allDay: false
    }
];

// export type SubjectGroupList = Record<EducationLevelGroup, SubjectData[]>;

const getGroupList = async (params: GetListParams): Promise<CalendarProps['events']> => {
    // const {educationLevelGroup, startDate, endDate}= params.meta;

    // const response = await axiosApiInstance.get(Resource.SCHEDULE, {
    //     params: {
    //         targetGradeRange: EducationLevelGradeRange[educationLevelGroup],
    //         startDate,
    //         endDate
    //     }
    // });

    // return response.data;
    return new Promise((resolve) => setTimeout(() => resolve(scheduleMock), 1000));
};

export default { getGroupList };
