import React from 'react';
import { CheckboxGroupInput, CheckboxGroupInputProps, required as requiredValidate } from 'react-admin';
import cn from 'classnames';
import './index.css';

const CheckboxIcon: React.FC<{ disabled: boolean }> = ({ disabled }) => {
    return <div className={cn('checkbox_icon', disabled ? 'checkbox_icon__disabled' : '')} />;
};

const CheckboxCheckedIcon = () => {
    return <div className="checkbox_icon_checked" />;
};

interface CheckboxProps extends CheckboxGroupInputProps {
    choices: Object[];
    disabled?: boolean;
    labelPlacement?: 'bottom' | 'end' | 'start' | 'top';
    required?: boolean;
    label?: string;
}

const ShsCheckboxGroup: React.FC<CheckboxProps> = (
    { source, choices, disabled = false, label, required, validate = [] },
    props
) => {
    const isRequired = () => (required ? '*' : '');
    const validateFn = [
        ...(Array.isArray(validate) ? validate : [validate]),
        ...(required ? [requiredValidate()] : [])
    ];

    return (
        <div className="text_field__container top">
            {label && (
                <label className="text_field__label">
                    {label || ''}
                    {isRequired()}
                </label>
            )}
            <CheckboxGroupInput
                source={source}
                choices={choices}
                options={{
                    icon: <CheckboxIcon disabled={disabled} />,
                    checkedIcon: <CheckboxCheckedIcon />
                }}
                label={false}
                validate={validateFn}
                {...props}
            />
        </div>
    );
};

export default ShsCheckboxGroup;
