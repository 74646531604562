import React from 'react';
import { Edit, SimpleForm, useCreatePath } from 'react-admin';
import { Container, Typography } from '@mui/material';

import NoAccess from 'src/components/common/Errors/NoAccess';
import WithRole from 'src/components/common/WithRole';
import { UserRole } from 'src/auth/types';
import { ShsReturnButton } from '../../common';
import { palette } from '../../../Theme';
import { LawInputsCard } from '../common/LawInputsCard';
import { EditLawActionToolbar } from './EditLawActionToolbar';

export const EditLaw: React.FC = () => {
    const createPath = useCreatePath();

    return (
        <WithRole roles={[UserRole.ADMIN, UserRole.SYSTEM_ADMIN]} orElse={<NoAccess />}>
            <Container>
                <ShsReturnButton
                    to={createPath({ resource: 'laws', type: 'list' })}
                    label="Назад до законодавства"
                    sx={{ color: palette.primary.grayBluish }}
                />
                <Typography fontSize={40} fontWeight={700} sx={{ mb: 3 }}>
                    Редагувати законопроєкт
                </Typography>
                <Edit sx={{ '.RaEdit-card': { backgroundColor: 'transparent', boxShadow: 'none' } }}>
                    <SimpleForm toolbar={false} mode="onBlur" sx={{ padding: 0 }}>
                        <LawInputsCard />
                        <EditLawActionToolbar />
                    </SimpleForm>
                </Edit>
            </Container>
        </WithRole>
    );
};
