import React from 'react';
import { email, FormDataConsumer } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Typography, Grid, Box } from '@mui/material';

import { Theme } from 'src/Theme';
import { ChildFormFields, AdditionalEducationActionList } from 'src/constants';
import { ShsTextInput, ShsPhoneInput, ShsCheckbox, ShsRadioButtonGroup } from 'src/components/common';

const EducationInstitutionData: React.FC = () => {
    const formContext = useFormContext();
    return (
        <>
            <Typography variant="h6" component="h6" mb={3} mt={2} fontWeight="600">
                Відомості про освіту дитини за місцем проживання
            </Typography>
            <Grid container columnSpacing={4} rowSpacing={1}>
                <Grid item xs={12}>
                    <ShsTextInput
                        source={`${ChildFormFields.VISIT}.${ChildFormFields.EDUCATIONAL_INSTITUTION_REFERENCE}`}
                        label="Заклад освіти за місцем проживання"
                        placeholder="Посилання на заклад освіти в ebdo"
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <ShsPhoneInput
                        source={`${ChildFormFields.VISIT}.${ChildFormFields.EDUCATIONAL_INSTITUTION_PHONE}`}
                        label="Номер телефону закладу освіти"
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <ShsTextInput
                        source={`${ChildFormFields.VISIT}.${ChildFormFields.EDUCATIONAL_INSTITUTION_EMAIL}`}
                        label="Email закладу освіти"
                        validate={email()}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ShsCheckbox
                        source={`${ChildFormFields.VISIT}.${ChildFormFields.EDUCATIONAL_INSTITUTION_ADDITIONAL_ACTION}.enabled`}
                        label="Потрібна організація освітнього процесу в закладі загальної середньої освіти учнем/ученицею якого вони є"
                        onChange={(value: boolean) => {
                            if (!value) {
                                formContext.setValue('visit.additionalActionRequired.action', null);
                            }
                        }}
                        sx={{ mb: 4 }}
                    />
                    <FormDataConsumer>
                        {({ formData }) => {
                            const isAdditionalAction =
                                formData[ChildFormFields.VISIT]?.[
                                    ChildFormFields.EDUCATIONAL_INSTITUTION_ADDITIONAL_ACTION
                                ]?.enabled;
                            return (
                                isAdditionalAction && (
                                    <Box sx={{ mt: -2, ml: 2 }}>
                                        <Typography
                                            component="span"
                                            fontWeight="400"
                                            color={Theme.palette.primary.optional}
                                        >
                                            За формою:
                                        </Typography>
                                        <ShsRadioButtonGroup
                                            source={`${ChildFormFields.VISIT}.${ChildFormFields.EDUCATIONAL_INSTITUTION_ADDITIONAL_ACTION}.action`}
                                            choices={AdditionalEducationActionList}
                                            label=""
                                            row={false}
                                            required
                                        />
                                    </Box>
                                )
                            );
                        }}
                    </FormDataConsumer>
                </Grid>
            </Grid>
        </>
    );
};

export default EducationInstitutionData;
