import type {
    DataProvider,
    CreateParams,
    DeleteParams,
    DeleteManyParams,
    GetListParams,
    GetManyParams,
    GetManyReferenceParams,
    GetOneParams,
    UpdateParams,
    UpdateManyParams
} from 'react-admin';

import axiosApiInstance, { postRequestCacheConfig } from 'src/api/axiosConfig';
import ApiMocks from 'src/api/mocks';
import employees from './employees';
import children from './children';
import { FILTER_EMPTY_VALUE, Resource } from '../../constants';
import subjects from './subjects';
import schedule from './schedule';

const resourcesAPI: { [key in string]: any } = {
    [Resource.EMPLOYEES]: employees,
    [Resource.SUBJECTS]: subjects,
    [Resource.CHILDREN]: children,
    [Resource.SCHEDULE]: schedule
};

export const create: DataProvider['create'] = (resource: string, params: CreateParams) => {
    if (resourcesAPI[resource]?.create) {
        return resourcesAPI[resource]?.create(params);
    }
    return axiosApiInstance.post(`${resource}/add`, params.data).then((response) => response);
};

export const deleteApi: DataProvider['delete'] = (resource: string, params: DeleteParams) => {
    if (resourcesAPI[resource]?.deleteOne) {
        return resourcesAPI[resource]?.deleteOne(params);
    }

    return axiosApiInstance.delete(`${resource}/${params.id}`);
};

export const deleteMany: DataProvider['deleteMany'] = (resource: string, params: DeleteManyParams) => {
    // TODO Implement Later
    return new Promise((resolveOuter) => {
        resolveOuter(
            new Promise((resolveInner) => {
                console.log(resource, params);
                setTimeout(resolveInner, 1000);
            })
        );
    });
};

export const getList: DataProvider['getList'] = (resource: string, params: GetListParams) => {
    if (resource === 'reports/services') {
        if (params.meta?.fetchUrl) {
            return axiosApiInstance.get(params.meta.fetchUrl).then((response) => ({
                data: response.data.content || [],
                total: response.data.totalElements || 0
            }));
        } else {
            return Promise.resolve({ data: [], total: 0 });
        }
    }

    for (let [key, val] of Object.entries(params.filter)) {
        if (val === FILTER_EMPTY_VALUE) {
            delete params.filter[key];
        }
    }

    if (resourcesAPI[resource]?.getList) {
        return resourcesAPI[resource]?.getList(params);
    }

    let { keyword, ...filters } = params.filter;
    filters = Object.keys(filters).length ? filters : undefined;

    const pageRequest = {
        page: params.pagination.page - 1,
        size: params.pagination.perPage,
        sort: params.sort && { properties: [params.sort.field], direction: params.sort.order },
        keyword,
        filters
    };

    return axiosApiInstance
        .post(resource, pageRequest, postRequestCacheConfig)
        .then((response) => ({ data: response.data.content, total: response.data.totalElements }));
};

export const getGroupList: DataProvider['getList'] = (resource: string, params: GetListParams) => {
    if (resourcesAPI[resource]?.getGroupList) {
        return resourcesAPI[resource].getGroupList(params);
    }

    // TODO Fix dummy
    return new Promise((resolveOuter) => {
        resolveOuter(
            new Promise((resolveInner) => {
                console.log(resource, params);
                setTimeout(resolveInner, 1000);
            })
        );
    });
};

export const getMany: DataProvider['getMany'] = (resource: string, params: GetManyParams) => {
    // TODO Implement Later
    return new Promise((resolveOuter) => {
        resolveOuter(
            new Promise((resolveInner) => {
                console.log(resource, params);
                setTimeout(resolveInner, 1000);
            })
        );
    });
};

export const getManyReference: DataProvider['getManyReference'] = (
    resource: string,
    params: GetManyReferenceParams
) => {
    // TODO Implement Later
    return new Promise((resolveOuter) => {
        resolveOuter(
            new Promise((resolveInner) => {
                console.log(resource, params);
                setTimeout(resolveInner, 1000);
            })
        );
    });
};

export const getOne: DataProvider['getOne'] = (resource: string, params: GetOneParams) => {
    const { useMock, tab } = params.meta || {};

    if (useMock) {
        const mock = ApiMocks[resource]['getOne' + (tab ? `/${tab}` : '')];
        return Promise.resolve({
            data: { ...mock, id: params.id }
        });
    }

    if (resourcesAPI[resource]?.getOne) {
        return resourcesAPI[resource].getOne(params);
    }

    return axiosApiInstance.get(`${resource}/${params.id}`).then((response) => response);
};

export const update: DataProvider['update'] = (resource: string, params: UpdateParams) => {
    if (resourcesAPI[resource]?.update) {
        return resourcesAPI[resource]?.update(params);
    }

    return axiosApiInstance.put(`${resource}/update`, params.data).then((response) => response);
};

export const updateMany: DataProvider['updateMany'] = (resource: string, params: UpdateManyParams) => {
    // TODO Implement Later
    return new Promise((resolveOuter) => {
        resolveOuter(
            new Promise((resolveInner) => {
                console.log(resource, params);
                setTimeout(resolveInner, 1000);
            })
        );
    });
};
