import * as React from 'react';
import { useEditController, Edit, SimpleForm, useCreatePath, TextInput, Loading } from 'react-admin';
import { Container, Typography } from '@mui/material';

import { ShsReturnButton } from 'src/components/common';
import { editContainerStyles } from 'src/styles';
import { ChildFormFields } from 'src/constants';

import Toolbar from './Toolbar';
import { PersonalData, EducationInstitutionData, VisitorsList, EducationChildData } from './FieldGroups';

export const ChildEdit: React.FC = () => {
    const { isLoading } = useEditController();
    const createPath = useCreatePath();
    if (isLoading) {
        return <Loading />;
    }
    return (
        <Container sx={editContainerStyles} maxWidth={false}>
            <ShsReturnButton to={createPath({ resource: 'children', type: 'list' })} label="Назад до списку дітей" />
            <Edit mutationMode="pessimistic" queryOptions={{ meta: { useMock: false } }} redirect="show">
                <Typography variant="h4" component="h3" mb={4} fontWeight="700">
                    Редагувати
                </Typography>
                <SimpleForm toolbar={<Toolbar editMode />}>
                    <TextInput sx={{ display: 'none' }} source={ChildFormFields.ID} />
                    <TextInput sx={{ display: 'none' }} source={ChildFormFields.VERSION} />

                    <TextInput sx={{ display: 'none' }} source={`${ChildFormFields.VISIT}.${ChildFormFields.ID}`} />
                    <TextInput
                        sx={{ display: 'none' }}
                        source={`${ChildFormFields.VISIT}.${ChildFormFields.VERSION}`}
                    />

                    <PersonalData />
                    <EducationInstitutionData />
                    <VisitorsList />
                    <EducationChildData />
                </SimpleForm>
            </Edit>
        </Container>
    );
};
