import { Admin, Resource } from 'react-admin';
import { useNavigate, useLocation } from 'react-router-dom';
import { Theme } from 'src/Theme';
import LoginPage from 'src/components/LoginPage';
import { CustomLayout } from 'src/components/Layout';
import authProvider, { getAuthFromLocalStorage } from 'src/auth/authProvider';
import i18nProvider from 'src/i18n';
import dataProvider from 'src/api';
import Loader from 'src/components/common/Loader';

import { Resource as ResourceName } from './constants';
import Children from './components/Children';
import Employees from './components/Employees';
import Laws from './components/Laws';
import NotFound from './components/common/Errors/NotFound';
import EducationalCenters from './components/EducationalCenters';
import Subjects from './components/Subjects';
import Schedule from './components/Schedule';
import Reports from './components/Reports';

function App() {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const authUser = getAuthFromLocalStorage();

    if (!authUser && !pathname.startsWith('/login')) {
        setTimeout(() => navigate('/login'), 500);
        return <Loader />;
    }

    return (
        <Admin
            theme={Theme}
            authProvider={authProvider}
            dataProvider={dataProvider}
            i18nProvider={i18nProvider}
            loginPage={<LoginPage />}
            catchAll={NotFound}
            layout={CustomLayout}
        >
            {/*TODO add resources here*/}
            <Resource name={ResourceName.CENTERS} {...EducationalCenters} />
            <Resource name={ResourceName.CHILDREN} {...Children} />
            <Resource name={ResourceName.EMPLOYEES} {...Employees} />
            <Resource name={ResourceName.SCHEDULE} {...Schedule} />
            <Resource name={ResourceName.LAWS} {...Laws} />
            <Resource name={ResourceName.SUBJECTS} {...Subjects} />
            <Resource name={ResourceName.REPORTS} {...Reports} />
        </Admin>
    );
}

export default App;
