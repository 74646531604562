import { setTime } from 'src/utils/centers';
import { EducationLevelGroup } from '../school/schoolLevel.enum';
import { EducationLevelGradeRange } from 'src/api/REST/subjects';

export enum CenterFormFields {
    ID = 'id',
    VERSION = 'version',

    ACTIVE = 'active',
    NAME = 'name',
    SHORT_NAME = 'shortName',
    PHONE = 'phone',
    CITY = 'city',
    ADDRESS = 'address',

    WORK_START = 'workStart',
    WORK_END = 'workEnd',
    BREAK_START = 'breakStart',
    BREAK_END = 'breakEnd',

    LESSONS_DURATION = 'lessonsDuration',
    BEFORE_SCHOOL_EDUCATION = 'beforeSchoolEducation',
    FIFTH_TENTH_GRADE = 'fifthTenthGrade',
    FIRST_FOURTH_GRADE = 'firstFourthGrade',

    LESSON_DURATION = 'lessonDuration',
    WEEKDAYS = 'weekdays',
    WEEKENDS = 'weekends',

    FROM = 'from',
    TO = 'to',

    FIRST_LESSON = 'firstLesson',
    SECOND_LESSON = 'secondLesson',
    THIRD_LESSON = 'thirdLesson',
    FOURTH_LESSON = 'fourthLesson',
    FIFTH_LESSON = 'fifthLesson',
    SIXTH_LESSON = 'sixthLesson',
    SEVENTH_LESSON = 'seventhLesson',
    EIGHTH_LESSON = 'eighthLesson',

    CENTER_DEPARTMENTS = 'hospitalDepartments',

    CLASSROOM_LOCATIONS = 'classroomLocations',
    LOCATIONS_NAME = 'name',
    BUILDING = 'building',
    FLOOR = 'floor',
    BLOCK = 'block',
    ROOM_NUMBER = 'roomNumber',
    MAX_SEATING_CAPACITY = 'maxSeatingCapacity',
    TARGER_GRADE_RANGE = 'targetGradeRange'
}

export enum LessonsDuration {
    MINUTES30 = 30,
    MINUTES35 = 35,
    MINUTES40 = 40,
    MINUTES45 = 45,
    MINUTES50 = 50,
    MINUTES55 = 55,
    MINUTES60 = 60
}

export const LessonsDurationList = [
    { id: LessonsDuration.MINUTES30, name: '30 хвилин' },
    { id: LessonsDuration.MINUTES35, name: '35 хвилин' },
    { id: LessonsDuration.MINUTES40, name: '40 хвилин' },
    { id: LessonsDuration.MINUTES45, name: '45 хвилин' },
    { id: LessonsDuration.MINUTES50, name: '50 хвилин' },
    { id: LessonsDuration.MINUTES55, name: '55 хвилин' },
    { id: LessonsDuration.MINUTES60, name: '60 хвилин' }
];

export const SchoolLabels = [
    { id: EducationLevelGradeRange[EducationLevelGroup.PRESCHOOL], name: 'Дошкільна освіта' },
    { id: EducationLevelGradeRange[EducationLevelGroup.JUNIOR_SCHOOL], name: '1-4 класи' },
    { id: EducationLevelGradeRange[EducationLevelGroup.HIGH_SCHOOL], name: '5-11 класи' }
];

export const centerDefaultValues = {
    [CenterFormFields.ACTIVE]: true,
    [CenterFormFields.NAME]: '',
    [CenterFormFields.SHORT_NAME]: '',
    [CenterFormFields.CITY]: '',
    [CenterFormFields.PHONE]: '',
    [CenterFormFields.ADDRESS]: '',
    [CenterFormFields.WORK_START]: setTime(9, 0, 0),
    [CenterFormFields.WORK_END]: setTime(18, 0, 0),
    [CenterFormFields.BREAK_START]: setTime(13, 0, 0),
    [CenterFormFields.BREAK_END]: setTime(14, 0, 0),
    [CenterFormFields.LESSONS_DURATION]: {
        [CenterFormFields.BEFORE_SCHOOL_EDUCATION]: {
            [CenterFormFields.LESSON_DURATION]: LessonsDuration.MINUTES45,
            [CenterFormFields.WEEKDAYS]: {
                [CenterFormFields.FIRST_LESSON]: {
                    [CenterFormFields.FROM]: setTime(10, 0, 0),
                    [CenterFormFields.TO]: setTime(10, 45, 0)
                }
            }
        }
    },
    [CenterFormFields.CLASSROOM_LOCATIONS]: [
        {
            name: '',
            building: '',
            floor: '',
            block: '',
            roomNumber: '',
            maxSeatingCapacity: '',
            targetGradeRange: []
        }
    ],
    [CenterFormFields.CENTER_DEPARTMENTS]: ['']
};
