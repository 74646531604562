import * as React from 'react';
import { Create, SimpleForm, useCreatePath, useRedirect } from 'react-admin';
import { Container, Typography } from '@mui/material';

import { ShsReturnButton } from 'src/components/common';
import { ChildFormDefaults } from 'src/constants';
import { editContainerStyles } from 'src/styles';

import AddToolbar from './Toolbar';
import { PersonalData, EducationInstitutionData, VisitorsList, EducationChildData } from './FieldGroups';

export const ChildAdd: React.FC = () => {
    const redirect = useRedirect();
    const createPath = useCreatePath();

    const onSuccess = () => {
        redirect(createPath({ resource: 'children', type: 'list' }));
    };

    return (
        <Container sx={editContainerStyles} maxWidth={false}>
            <ShsReturnButton to={createPath({ resource: 'children', type: 'list' })} label="Назад до списку дітей" />
            <Create mutationOptions={{ onSuccess }}>
                <Typography variant="h4" component="h3" mb={4} fontWeight="700">
                    Додати дитину
                </Typography>
                <SimpleForm defaultValues={ChildFormDefaults} toolbar={<AddToolbar />}>
                    <PersonalData />
                    <EducationInstitutionData />
                    <VisitorsList />
                    <EducationChildData />
                </SimpleForm>
            </Create>
        </Container>
    );
};
