import { ChildrenList } from './ChildrenList';
import { ChildAdd, ChildEdit } from './ChildAddEdit';
import { ChildShow } from './ChildShow';

export default {
    list: ChildrenList,
    show: ChildShow,
    create: ChildAdd,
    edit: ChildEdit
};
