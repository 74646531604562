import React from 'react';
import { Chip } from '@mui/material';
import type { SxProps } from '@mui/material';

import { STATUS, StatusLabel } from 'src/constants';
import { Theme } from 'src/Theme';

const statusBadgeStyles = {
    height: '24px',
    fontSize: '12px',
    fontWeight: 500,
    borderRadius: '30px',
    display: 'inline-block',
    '& > span': {
        padding: '0 18px',
        lineHeight: '24px'
    }
};

const activeStyles = {
    ...statusBadgeStyles,
    background: Theme.palette.success.main,
    color: Theme.palette.success.contrastText
};

const inactiveStyles = {
    ...statusBadgeStyles,
    background: Theme.palette.primary.grayLight,
    color: Theme.palette.primary.darkMedium
};

const suspendedStyles = {
    ...statusBadgeStyles,
    background: Theme.palette.danger.warning,
    color: Theme.palette.danger.main
};

const StatusStyles = {
    [STATUS.ACTIVE]: activeStyles,
    [STATUS.INACTIVE]: inactiveStyles,
    [STATUS.SUSPENDED]: suspendedStyles
};

interface StatusBadgeProps {
    isActive?: boolean;
    status?: STATUS;
    sx?: SxProps;
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ isActive = true, status, sx = {} }) => {
    const statusValue = status || (isActive ? STATUS.ACTIVE : STATUS.INACTIVE);
    return (
        <Chip sx={{ ...(StatusStyles[statusValue] || statusBadgeStyles), ...sx }} label={StatusLabel[statusValue]} />
    );
};

export default StatusBadge;
