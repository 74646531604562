import { useState } from 'react';
import { SimpleForm } from 'react-admin';
import { Container, Card, Tabs, Tab, Box, Stack } from '@mui/material';
import { EducationLevelGroup } from 'src/constants';
import { palette } from 'src/Theme';
import { ScheduleToolbar } from './ScheduleToolbar';
import { ScheduleCalendar } from './ScheduleCalendar';
import { ScheduleSidebar } from './ScheduleSidebar';

export const ScheduleList: React.FC = () => {
    const [activeTab, setActiveTab] = useState<EducationLevelGroup>(EducationLevelGroup.PRESCHOOL);

    return (
        <Box>
            <SimpleForm toolbar={false} sx={{ padding: 0 }}>
                <Stack direction={'row'} sx={{ backgroundColor: 'white' }}>
                    <Container sx={{ padding: '16px 8px', minWidth: '280px' }}>
                        <ScheduleSidebar />
                    </Container>
                    <Container sx={{ backgroundColor: palette.primary.grayLight, margin: 0 }}>
                        <ScheduleToolbar />
                        <Card>
                            <Tabs value={activeTab} onChange={(_, value) => setActiveTab(value)}>
                                <Tab value={EducationLevelGroup.PRESCHOOL} label="Дошкільна освіта" />
                                <Tab value={EducationLevelGroup.JUNIOR_SCHOOL} label="1-4 класи" />
                                <Tab value={EducationLevelGroup.HIGH_SCHOOL} label="5-11 класи" />
                            </Tabs>
                            <ScheduleCalendar educationLevelGroup={activeTab} />
                        </Card>
                    </Container>
                </Stack>
            </SimpleForm>
        </Box>
    );
};
