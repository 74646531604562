import { ArrayInput, FormDataConsumer, SimpleFormIterator, minLength } from 'react-admin';
import { Container, Typography, Box, Grid } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';

import { ShsButton, ShsDatePicker, ShsTextInput } from 'src/components/common';
import { disableFirstRemoveStyle, formIteratorStyles, titleFontStyle } from 'src/styles';
import { LinkInput } from '../components/LinkInput';

const EmployeeDocumentsForm = () => {
    return (
        <Container>
            <Typography sx={titleFontStyle} variant="h6" component="h6" mb={3}>
                Диплом
            </Typography>
            <ArrayInput
                source="details.diplomas"
                label=""
                defaultValue={[{ diplomaLink: '', diplomaSupplementLink: '' }]}
                validate={minLength(1, 'Потрібно додати хоча б один диплом')}
            >
                <SimpleFormIterator
                    fullWidth
                    disableClear
                    disableReordering
                    sx={{ ...disableFirstRemoveStyle, ...formIteratorStyles }}
                    addButton={
                        <Box pt={2} borderTop="solid 1px rgba(0, 0, 0, 0.12)" width="100%">
                            <ShsButton
                                styleType="text"
                                sx={{ p: 0 }}
                                label="Додати диплом"
                                icon={<AddCircleOutline />}
                            />
                        </Box>
                    }
                >
                    <FormDataConsumer>
                        {({ getSource }) => (
                            <Grid container columnSpacing={4} rowSpacing={1} mt={2}>
                                <Grid item xs={12}>
                                    <LinkInput source={getSource('diplomaLink')} label="Диплом" required />
                                    <LinkInput
                                        source={getSource('diplomaSupplementLink')}
                                        label="Додаток до диплому"
                                        required
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>

            <Typography sx={titleFontStyle} variant="h6" component="h6" mt={3} mb={3}>
                Підвищення кваліфікації
            </Typography>
            <ArrayInput source="details.refresherCertificates" label="" defaultValue={[{ certificateLink: '' }]}>
                <SimpleFormIterator
                    fullWidth
                    disableClear
                    disableReordering
                    sx={{ ...disableFirstRemoveStyle, ...formIteratorStyles }}
                    addButton={
                        <Box pt={2} borderTop="solid 1px rgba(0, 0, 0, 0.12)" width="100%">
                            <ShsButton
                                styleType="text"
                                sx={{ p: 0 }}
                                label="Додати сертифікат"
                                icon={<AddCircleOutline />}
                            />
                        </Box>
                    }
                >
                    <FormDataConsumer>
                        {({ getSource }) => (
                            <Grid container columnSpacing={4} rowSpacing={1} mt={2}>
                                <Grid item xs={12}>
                                    <LinkInput
                                        source={getSource('certificateLink')}
                                        label="Сертифікати підвищення кваліфікації"
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>

            <Typography sx={titleFontStyle} variant="h6" component="h6" mt={3} mb={3}>
                Додаткові документи
            </Typography>

            <LinkInput source="details.employmentHistoryLink" label="Трудова книжка" required />
            <LinkInput source="details.medicalHistoryLink" label="Медична книжка" required />

            <Grid container>
                <Grid item xs={12}>
                    <ShsDatePicker
                        source="details.medicalCheckup"
                        label="Дата медичного огляду"
                        labelPosition="left"
                        required
                    />
                </Grid>
            </Grid>

            <LinkInput
                source="details.nameChangeDocumentLink"
                label="Документ про зміну прізвища, імені або по-батькові"
            />
            <LinkInput source="details.temporarilyDisplacedDocumentLink" label="Довідка ВПО" />
            <LinkInput source="details.attestationLetterLink" label="Атестаційний лист" />

            <ArrayInput source="details.additionalDocuments" label="">
                <SimpleFormIterator
                    fullWidth
                    disableClear
                    disableReordering
                    sx={formIteratorStyles}
                    addButton={
                        <Box pt={2} borderTop="solid 1px rgba(0, 0, 0, 0.12)" width="100%">
                            <ShsButton
                                styleType="text"
                                sx={{ p: 0 }}
                                label="Додати інший документ"
                                icon={<AddCircleOutline />}
                            />
                        </Box>
                    }
                >
                    <FormDataConsumer>
                        {({ getSource }) => (
                            <Grid container columnSpacing={4} rowSpacing={1} mt={2}>
                                <Grid item xs={6}>
                                    <ShsTextInput
                                        source={getSource('name')}
                                        required
                                        placeholder="Вкажіть назву документу"
                                        sx={{ width: '100%' }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <ShsTextInput
                                        source={getSource('link')}
                                        required
                                        placeholder="Вкажіть лінк на файл"
                                        sx={{ width: '100%' }}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>
        </Container>
    );
};

export default EmployeeDocumentsForm;
