import React from 'react';
import { Grid, Typography, Icon } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';
import { ShsForwardButton } from '../../common';

interface InfoItemProps {
    icon: SvgIconComponent;
    title: string;
    subtitle: string;
    value: string | number;
}

interface InfoSectionProps {
    title: string;
    buttonLabel: string;
    onButtonClick: () => void;
    infoItems: InfoItemProps[];
}

export const InfoSection: React.FC<InfoSectionProps> = ({ title, buttonLabel, onButtonClick, infoItems }) => (
    <Grid container spacing={2}>
        <Grid item xs={12} container justifyContent="space-between" alignItems="center">
            <Grid item>
                <Typography variant="h6" component="h6" fontWeight="600">
                    {title}
                </Typography>
            </Grid>
            <Grid item>
                <ShsForwardButton onClick={onButtonClick} label={buttonLabel} />
            </Grid>
        </Grid>
        <Grid item xs={12} container spacing={2}>
            {infoItems.map((item, index) => (
                <Grid key={index} item xs={6} container alignItems="center">
                    <Grid item>
                        <Icon component={item.icon} style={{ fontSize: 40, marginRight: 16 }} />
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle1">{item.title}</Typography>
                        <Typography variant="caption">{item.subtitle}</Typography>
                        <Typography variant="h4">{item.value}</Typography>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    </Grid>
);

export type { InfoItemProps, InfoSectionProps };
