import React, { useState, useEffect } from 'react';
import { Filter, List, TopToolbar, useCreatePath } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
import { ShsDatePicker, ShsTitle, ShsAutoCompleteWithLoad } from '../common';
import { listContainerStyles } from 'src/styles';
import { EducationCenterType } from '../Employees/types';
import { FILTER_EMPTY_VALUE } from 'src/constants';
import EmployeesReport from './EmployeesReport';
import { ChildrenReport } from './ChildrenReport';
import PeopleIcon from '@mui/icons-material/People';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { InfoSection, InfoItemProps } from './components/InfoSection';

export const Report: React.FC = () => {
    const [currentView, setCurrentView] = useState<'main' | 'employees' | 'children'>('main');
    const createPath = useCreatePath();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname.endsWith('/employees')) {
            setCurrentView('employees');
        } else if (location.pathname.endsWith('/children')) {
            setCurrentView('children');
        } else {
            setCurrentView('main');
        }
    }, [location.pathname]);

    const handleEmployeesReportClick = () => {
        const path = createPath({ resource: 'reports', type: 'employees' });
        navigate(path);
    };

    const handleChildrenReportClick = () => {
        const path = createPath({ resource: 'reports', type: 'children' });
        navigate(path);
    };

    const handleReturn = () => {
        const path = createPath({ resource: 'reports', type: 'list' });
        navigate(path);
    };

    if (currentView === 'employees') {
        return <EmployeesReport onReturn={handleReturn} />;
    }

    if (currentView === 'children') {
        return <ChildrenReport onReturn={handleReturn} />;
    }

    const childrenInfo: InfoItemProps[] = [
        {
            icon: PeopleIcon,
            title: 'Кількість дітей',
            subtitle: 'за обраний період',
            value: 0
        },
        {
            icon: AccessTimeIcon,
            title: 'Кількість дітей',
            subtitle: `на останню дату (${'selectedDate'})`,
            value: 0
        }
    ];

    const employeesInfo: InfoItemProps[] = [
        {
            icon: PeopleIcon,
            title: 'Кількість працівників',
            subtitle: 'за обраний період',
            value: 0
        },
        {
            icon: AccessTimeIcon,
            title: 'Надані послуги',
            subtitle: 'за обраний період',
            value: '0 год'
        }
    ];

    return (
        <Container sx={listContainerStyles}>
            <ShsTitle title="Звіти" />
            <List<EducationCenterType>
                resource="reports/services"
                exporter={false}
                empty={false}
                actions={false}
                filters={
                    <Filter>
                        <ShsAutoCompleteWithLoad
                            source="educationCenterId"
                            pageUrl="centers"
                            filters={{ isActive: true }}
                            emptyValue={FILTER_EMPTY_VALUE}
                            emptyText="Усі"
                            alwaysOn
                            keyField="id"
                            textField="shortName"
                            resultLimit={100}
                            label="Освітній центр"
                        />
                        <ShsDatePicker source={'startDate'} label="Початок періоду" alwaysOn required />
                        <ShsDatePicker source={'endDate'} label="Кінець періоду" alwaysOn required />
                    </Filter>
                }
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <InfoSection
                            title="Інформація про дітей"
                            buttonLabel="Список дітей"
                            onButtonClick={handleChildrenReportClick}
                            infoItems={childrenInfo}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InfoSection
                            title="Інформація про працівників"
                            buttonLabel="Список працівників"
                            onButtonClick={handleEmployeesReportClick}
                            infoItems={employeesInfo}
                        />
                    </Grid>
                </Grid>
            </List>
        </Container>
    );
};
