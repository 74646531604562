import React from 'react';
import { email } from 'react-admin';
import { Grid, Box } from '@mui/material';

import { ChildFormFields } from 'src/constants';
import { ShsTextInput, ShsPhoneInput, ShsToggle } from 'src/components/common';
import { validate } from 'src/utils';

interface VisitorDataProps {
    getSource?: (source: string) => string;
}

const VisitorData: React.FC<VisitorDataProps> = ({ getSource = (data: string) => data }) => (
    <>
        <Grid container columnSpacing={4} rowSpacing={1} sx={{ mt: 2 }}>
            <Grid item xs={12}>
                <ShsTextInput
                    source={getSource(`${ChildFormFields.PARENT}.${ChildFormFields.VISITOR_TAX_NUMBER}`)}
                    label="Ідентифікаційний номер платника податків (РНОКПП)"
                    validate={validate.taxNumberUA()}
                    inputProps={{ maxLength: 10 }}
                    required
                />
            </Grid>
            <Grid item xs={6}>
                <ShsTextInput
                    source={getSource(`${ChildFormFields.PARENT}.${ChildFormFields.VISITOR_LAST_NAME}`)}
                    label="Прізвище"
                    required
                />
            </Grid>
            <Grid item xs={6}>
                <ShsTextInput
                    source={getSource(`${ChildFormFields.PARENT}.${ChildFormFields.VISITOR_FIRST_NAME}`)}
                    label="Ім'я"
                    required
                />
            </Grid>
            <Box width="100%" />
            <Grid item xs={6}>
                <ShsTextInput
                    source={getSource(`${ChildFormFields.PARENT}.${ChildFormFields.VISITOR_PATRONYMIC}`)}
                    label="По батькові"
                />
            </Grid>
            <Grid item xs={6}>
                <ShsTextInput source={getSource(ChildFormFields.VISITOR_ROLE)} label="Роль представника" required />
            </Grid>
            <Box width="100%" />
            <Grid item xs={6}>
                <ShsPhoneInput
                    source={getSource(`${ChildFormFields.PARENT}.${ChildFormFields.VISITOR_PHONE}`)}
                    label="Контактний номер телефону"
                    required
                />
            </Grid>
            <Grid item xs={6}>
                <ShsTextInput
                    source={getSource(`${ChildFormFields.PARENT}.${ChildFormFields.VISITOR_EMAIL}`)}
                    label="Email"
                    validate={email()}
                />
            </Grid>
            <Grid item xs={12}>
                <ShsToggle source={getSource(ChildFormFields.VISITOR_ACTIVE)} label="Активний представник" />
            </Grid>
        </Grid>
    </>
);

export default VisitorData;
