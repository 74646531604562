import React from 'react';
import { MenuItemLink, MenuItemLinkProps, useSidebarState } from 'react-admin';
import { uniqueId } from 'lodash';
import { Box } from '@mui/material';
import cn from 'classnames';
import { MenuItemType } from './types';
import { ReactComponent as Logo } from 'src/assets/logo.svg';
import { ReactComponent as ShortLogo } from 'src/assets/short-logo.svg';
import { ReactComponent as EducationCenterIcon } from 'src/assets/education-center.svg';
import { ReactComponent as ReportIcon } from 'src/assets/grid-alt.svg';
import { ReactComponent as CollapseIcon } from 'src/assets/angel-double-left.svg';
import { ReactComponent as ExpandIcon } from 'src/assets/angel-double-right.svg';
import { ReactComponent as ChildrenIcon } from 'src/assets/users.svg';
import { ReactComponent as TeacherIcon } from 'src/assets/teacher.svg';
import { ReactComponent as CalendarIcon } from 'src/assets/calendar.svg';
import { ReactComponent as BrowserTimerIcon } from 'src/assets/browser-timer.svg';
import { ReactComponent as BookIcon } from 'src/assets/book.svg';
import { ReactComponent as ScaleIcon } from 'src/assets/scale.svg';
import { ReactComponent as InstractionIcon } from 'src/assets/instruction.svg';
import { ReactComponent as FeedbackIcon } from 'src/assets/feedback.svg';
import WithRole from 'src/components/common/WithRole';
import { UserRole } from 'src/auth/types';
import './index.css';
import { Resource } from 'src/constants';

const SHOULD_REPLACE_TO_REAL_DATA: MenuItemType[] = [
    { label: 'Звіти', icon: <ReportIcon />, navigateTo: '/reports' },
    {
        label: 'Освітні центри',
        icon: <EducationCenterIcon />,
        navigateTo: '/centers',
        eligibleRoles: [UserRole.SYSTEM_ADMIN, UserRole.SECRETARIAT, UserRole.ADMIN]
    },
    { label: 'Діти', icon: <ChildrenIcon />, navigateTo: '/children' },
    { label: 'Працівники центру', icon: <TeacherIcon />, navigateTo: '/employees' },
    { label: 'Розклад', icon: <CalendarIcon />, navigateTo: `/${Resource.SCHEDULE}` },
    { label: 'Календарне планування', icon: <BrowserTimerIcon />, navigateTo: '/planning' },
    { label: 'Навчальний план', icon: <BookIcon />, navigateTo: `/${Resource.SUBJECTS}` },
    { label: 'Законодавство', icon: <ScaleIcon />, navigateTo: '/laws' }
];

const ShsMenuItem = (props: MenuItemLinkProps) => (
    <MenuItemLink
        {...props}
        onPointerEnterCapture={() => {}}
        onPointerLeaveCapture={() => {}}
        sx={(theme) => ({
            '&.RaMenuItemLink-active': {
                borderRight: `3px solid ${theme.palette.primary.main}`,
                background: `${theme.palette.primary.dark}`,
                color: `${theme.palette.primary.main}`,
                fill: `${theme.palette.primary.main}`,
                stroke: `${theme.palette.primary.main}`
            },
            ...((typeof props.sx === 'function' ? props.sx(theme) : props.sx || {}) as any)
        })}
    />
);

export const Sidebar = () => {
    const [open, setOpen] = useSidebarState();

    return (
        <Box
            className="sidebar_container"
            sx={{
                width: open ? 280 : 56,
                transition: (theme) =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen
                    })
            }}
        >
            <div className={cn([!open ? 'short' : '', 'sidebar__logo_container'])}>
                {open ? <Logo /> : <ShortLogo />}
            </div>

            <div className="sidebar_content">
                <div>
                    {SHOULD_REPLACE_TO_REAL_DATA.map(({ label, icon, navigateTo, eligibleRoles }) => (
                        <WithRole roles={eligibleRoles} key={uniqueId(label)}>
                            <ShsMenuItem
                                key={navigateTo}
                                to={navigateTo}
                                primaryText={label}
                                leftIcon={icon}
                                state={{ _scrollToTop: true }}
                            />
                        </WithRole>
                    ))}
                    <hr className="sidebar_line" />
                </div>
            </div>

            <ShsMenuItem
                to="/instruction"
                primaryText="Інструкція"
                leftIcon={<InstractionIcon />}
                state={{ _scrollToTop: true }}
            />
            <ShsMenuItem
                to="/feedback"
                primaryText="Зворотній зв'язок"
                leftIcon={<FeedbackIcon />}
                state={{ _scrollToTop: true }}
                sx={{ marginBottom: '40px' }}
            />

            <MenuItemLink
                to="#"
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
                leftIcon={open ? <CollapseIcon /> : <ExpandIcon />}
                primaryText={open ? 'Згорнути' : 'Розгорнути'}
                onClick={() => setOpen(!open)}
                sx={{
                    '&.RaMenuItemLink-active': {
                        color: 'rgba(107, 114, 128, 0.7)'
                    }
                }}
            />
        </Box>
    );
};
