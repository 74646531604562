import * as React from 'react';
import { useEditController, Show, TabbedShowLayout, useCreatePath, Loading } from 'react-admin';
import { Container } from '@mui/material';

import TopToolbar from './TopToolbar';
import { ShsReturnButton } from 'src/components/common';
import GeneralTab from './GeneralTab';
import SchedulerTab from './SchedulerTab';
import { tabbedContainerStyles } from 'src/styles';

const tabbedLayoutStyles = {
    '& .RaTabbedShowLayout-content:has(.show-page-container.scheduler)': {
        padding: 0
    }
};

export const ChildShow: React.FC = () => {
    const { isLoading } = useEditController();
    const createPath = useCreatePath();
    if (isLoading) {
        return <Loading />;
    }
    return (
        <Container maxWidth={false} sx={tabbedContainerStyles}>
            <ShsReturnButton to={createPath({ resource: 'children', type: 'list' })} label="Назад до списку дітей" />
            <Show className="show-page-container" queryOptions={{ meta: { useMock: false } }} actions={<TopToolbar />}>
                <TabbedShowLayout sx={tabbedLayoutStyles}>
                    <TabbedShowLayout.Tab label="Загальна інформація">
                        <GeneralTab />
                    </TabbedShowLayout.Tab>
                    <TabbedShowLayout.Tab label="Розклад учня" path="scheduler">
                        <SchedulerTab />
                    </TabbedShowLayout.Tab>
                    <TabbedShowLayout.Tab label="Звітність">
                        <>ChildShow</>
                    </TabbedShowLayout.Tab>
                </TabbedShowLayout>
            </Show>
        </Container>
    );
};
