import React from 'react';
import { ArrayInput, FormDataConsumer, minLength, required, SimpleFormIterator } from 'react-admin';
import { disableFirstRemoveStyle, formIteratorStyles, titleFontStyle } from '../../../styles';
import { Box, Grid, Typography } from '@mui/material';
import { ShsButton, ShsCheckbox, ShsDateInput, ShsSelectInput } from '../../common';
import { ReactComponent as DeleteIcon } from '../../../assets/trash-can.svg';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ShsAutoCompleteWithLoad from '../../common/Fields/ShsAutoCompleteWithLoad';
import { StaffRateLabel, UserRoleLabel } from '../../../constants';
import { UserRole } from '../../../auth/types';
import DaySchedule from './DaySchedule';
import { DaysOfWeek, DaysOfWeekLabel } from '../../../constants';
import { Theme } from 'src/Theme';
import { ShsSelectArrayInput } from 'src/components/common/Fields/ShsSelectArrayInput';

const EXCLUDED_ROLES = [UserRole.SYSTEM_ADMIN, UserRole.SECRETARIAT, UserRole.PARENT];
const EXCLUDED_NON_SPECIAL_TEACHER_ROLES = [
    UserRole.SYSTEM_ADMIN,
    UserRole.ADMIN,
    UserRole.SECRETARIAT,
    UserRole.PARENT,
    UserRole.TEACHER,
    UserRole.PRESCHOOL_TEACHER,
    UserRole.JUNIORS_TEACHER,
    UserRole.ASSISTANT_JUNIORS_TEACHER,
    UserRole.ASSISTANT_PRESCHOOL_TEACHER
];

const EmploymentTypeForm: React.FC = () => {
    return (
        <ArrayInput source="employeeCenters" label="" defaultValue={[{}]}>
            <SimpleFormIterator
                fullWidth
                disableClear
                disableReordering
                sx={{
                    ...disableFirstRemoveStyle,
                    ...formIteratorStyles,
                    '& .RaSimpleFormIterator-line': {
                        flexDirection: 'column'
                    }
                }}
                getItemLabel={(index) => (
                    <Typography
                        sx={titleFontStyle}
                        variant="h6"
                        component="span"
                        width="100%"
                        mt={index !== 0 ? 3 : undefined}
                    >
                        Тип працевлаштування{index !== 0 ? ` - ${index + 1}` : ''}
                    </Typography>
                )}
                removeButton={
                    <ShsButton
                        styleType="text-danger"
                        sx={{ p: 0, mb: 1 }}
                        label="Видалити працевлаштування"
                        icon={<DeleteIcon />}
                    />
                }
                addButton={
                    <Box pt={2} borderTop="solid 1px rgba(0, 0, 0, 0.12)" width="100%">
                        <ShsButton
                            styleType="text"
                            sx={{ p: 0 }}
                            label="Додати послугу"
                            icon={<AddCircleOutlineIcon />}
                        />
                    </Box>
                }
            >
                <FormDataConsumer>
                    {({ getSource }) => (
                        <Grid container columnSpacing={4} rowSpacing={1} mt={2}>
                            <Grid item xs={6}>
                                <ShsAutoCompleteWithLoad
                                    source={getSource('educationCenterId')}
                                    required
                                    pageUrl="centers"
                                    filters={{ isActive: true }}
                                    validate={required('Вкажіть освітній центр')}
                                    keyField="id"
                                    textField="shortName"
                                    resultLimit={100}
                                    label="Освітній центр"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <ShsDateInput
                                    source={getSource('dateOfStarting')}
                                    required
                                    label="Дата початку трудових відносин"
                                />
                            </Grid>

                            <Grid item xs={12} sx={{ borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)' }}>
                                <ShsCheckbox
                                    source={getSource('staffMember')}
                                    label="Штатний працівник"
                                    defaultValue={false}
                                />
                                <Grid container columnSpacing={4} rowSpacing={1} mt={1}>
                                    <Grid item xs={6}>
                                        <ShsSelectArrayInput
                                            source={getSource('roles')}
                                            label="Роль"
                                            required
                                            choices={Object.entries(UserRoleLabel)
                                                .filter(([id]) => !EXCLUDED_ROLES.includes(id as UserRole))
                                                .map(([id, name]) => ({ id, name }))}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ShsSelectInput
                                            source={getSource('rate')}
                                            label="Розмір ставки"
                                            placeholder="Виберіть розмір ставки"
                                            required
                                            choices={Object.entries(StaffRateLabel)
                                                .filter(([id]) => !EXCLUDED_ROLES.includes(id as UserRole))
                                                .map(([id, name]) => ({ id, name }))}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <ShsCheckbox
                                    source={getSource('civilLawNatureContract')}
                                    label="Договір цивільно-правового характеру"
                                    defaultValue={false}
                                />
                                <Grid container columnSpacing={4} rowSpacing={1} mt={1}>
                                    <Grid item xs={6}>
                                        <ShsDateInput
                                            source={getSource('dateOfStarting')}
                                            required
                                            label="Дата початку трудових відносин"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ShsDateInput
                                            source={getSource('dateOfEnding')}
                                            required
                                            label="Дата завершення трудових відносин"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ShsSelectArrayInput
                                            source={getSource('roles')}
                                            label="Освітня роль"
                                            required
                                            choices={Object.entries(UserRoleLabel)
                                                .filter(
                                                    ([id]) =>
                                                        !EXCLUDED_NON_SPECIAL_TEACHER_ROLES.includes(id as UserRole)
                                                )
                                                .map(([id, name]) => ({ id, name }))}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        {/* Civil law nature contract fields are required only when checkbox is checked */}
                                        {/* TODO: Add 'required' when civil law nature contract switch works correctly */}
                                        <ShsAutoCompleteWithLoad
                                            multiselect
                                            source={getSource('subjectIds')}
                                            pageUrl="subjects"
                                            validate={minLength(1, 'Хоча б одна освітня послуга повина бути вказана')}
                                            keyField="id"
                                            textField="name"
                                            resultLimit={100}
                                            label="Освітня послуга"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            component="span"
                                            mb={1}
                                            fontWeight="400"
                                            color={Theme.palette.primary.optional}
                                        >
                                            Можливий графік роботи
                                        </Typography>
                                        {Object.keys(DaysOfWeek).map((dayKey, index) => {
                                            const day = DaysOfWeek[dayKey as keyof typeof DaysOfWeek];
                                            return (
                                                <Grid item xs={12} key={index}>
                                                    <DaySchedule
                                                        label={DaysOfWeekLabel[day]}
                                                        getSource={(source) =>
                                                            getSource(`possibleWorkSchedule.${day}.${source}`)
                                                        }
                                                    />
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                                <Grid container columnSpacing={4} rowSpacing={1} mt={1}></Grid>
                            </Grid>
                        </Grid>
                    )}
                </FormDataConsumer>
            </SimpleFormIterator>
        </ArrayInput>
    );
};

export default EmploymentTypeForm;
